#ham-icon {
  width: 10%;
  height: 100%;
  position: relative;
  z-index: 2000;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3%;
    width: 100%;
    background: var(--ham-color);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 45%;
    transform: translateY(-50%);
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 55%;
    transform: translateY(-50%);
  }
  span:nth-child(4) {
    top: 70%;
    transform: translateY(-50%);
  }
}

#ham-icon.open {
  span:nth-child(1) {
    top: 15%;
    width: 0%;
    left: 50%;
  }
  span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  span:nth-child(4) {
    top: 15%;
    width: 0%;
    left: 50%;
  }
}

@media (max-width: 992px) {
  #ham-icon {
    width: 15%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  :root {
    --content-radius: 75px;
  }
}

@media (min-width: 1400px) and (max-width: 2500px) {
  :root {
    --content-radius: 75px;
  }
}

@media (min-width: 2500px) {
  #ham-icon {
    width: 7%;
    height: 100%;
    position: relative;
    z-index: 2000;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 5%;
      width: 100%;
      background: var(--ham-color);
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
  }
}
