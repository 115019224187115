.apeintosh-button {
  width: 40%;
  min-height: 50px;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.5s;
  -moz-box-shadow: inset 1.5px 1.5px 1.5px rgba(255, 255, 255, 0.4),
    inset -1.5px -1.5px 1.5px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 1.5px 1.5px 1.5px rgba(255, 255, 255, 0.4),
    inset -1.5px -1.5px 1.5px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0.15vw 0.15vw 0.15vw rgba(255, 255, 255, 0.4),
    inset -0.15vw -0.15vw 0.15vw rgba(0, 0, 0, 0.4);
}
.btn-blue {
  background-color: var(--light-blue);
  &:hover {
    background-color: rgb(54, 168, 166);
  }
}

@media (max-width: 992px) {
  .apeintosh-button {
    -moz-box-shadow: inset 1.5px 1.5px 1.5px rgba(255, 255, 255, 0.4),
      inset -1.5px -1.5px 1.5px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 1.5px 1.5px 1.5px rgba(255, 255, 255, 0.4),
      inset -1.5px -1.5px 1.5px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0.6vw 0.6vw 0.6vw rgba(255, 255, 255, 0.4),
      inset -0.6vw -0.6vw 0.6vw rgba(0, 0, 0, 0.4);
    border-radius: 2vw;
  }
}

@media (min-width: 3000px) {
  .apeintosh-button {
    -moz-box-shadow: inset 1.5px 1.5px 1.5px rgba(255, 255, 255, 0.4),
      inset -1.5px -1.5px 1.5px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 1.5px 1.5px 1.5px rgba(255, 255, 255, 0.4),
      inset -1.5px -1.5px 1.5px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0.25vw 0.25vw 0.25vw rgba(255, 255, 255, 0.4),
      inset -0.25vw -0.25vw 0.25vw rgba(0, 0, 0, 0.4);
    border-radius: 0.5vw;
  }
}
