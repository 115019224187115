.primary-button {
  border-radius: 25px;
  background-color: var(--light-blue);
  font-family: var(--subtitle-font);
  font-weight: 900;
  border: 0;
  padding: 7px 21px;
  color: var(--dark-blue);
  font-size: 18px;
}
@media (max-width: 1024px) {
  .nav-connect button {
      font-size: 13px;
      padding: 6px 10px;
      text-transform: uppercase;
      font-weight: 900;
      font-style: normal;
      width: 100%;
      cursor: pointer;
  }
}
