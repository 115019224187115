.tl-wrapper {
    padding: 1.5% !important;
    border-radius: 20px;
    .tl-content {
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 5%;
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
    }
}

@media (max-width: 992px) {
    .tl-wrapper {
        padding: 0.5%;
        .tl-content {
            font-size: 15px;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
     :root {
        --content-radius: 75px;
    }
}

@media (min-width: 1400px) and (max-width: 2500px) {
     :root {
        --content-radius: 75px;
    }
}

@media (min-width: 2500px) {
    .tl-wrapper {
        padding: 5px;
        border-radius: 20px;
        .tl-content {
            margin: 0;
            border-radius: 12px;
            padding: 5%;
            text-align: center;
            font-size: 80px;
            text-transform: uppercase;
        }
    }
}