.privacy {
  color: white;

  background-color: var(--dark-blue);
  width: 100%;
  height: 100%;
  margin: 0%;
  padding: 5% 10%;
  text-align: left;
  p {
    font-family: bilo, sans-serif;
    margin-left: 4%;
  }
  a {
    color: lightblue;
    font-size: 24px;
  }
}
