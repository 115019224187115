.s-home {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    position: relative;
    grid-template-areas: "a . " "b . " "c . ";
    justify-items: left;
    align-items: center;
    margin-bottom: 3em;
    svg {
        width: 100%;
        height: auto;
    }
    >.h-ape{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 25%;
        // margin-left: 5%;
        cursor: pointer;
        h2 {
            // font-family: var(--console-font);
            text-decoration: underline;
            font-size: 2em;
            margin: 5% 0 0 0;
            text-align: center;
            // color: var(--dark-purple);
            color: white;
        }
    }
}

.h-ape {
    grid-area: a;
}

@media (max-width: 768px) {
    .s-home{
        height: 80%;
    }
}

@media (max-width: 992px) {
    .s-home {
        svg {
            width: 80%;
            height: auto;
        }
        >.h-ape{
            h2 {
                font-size: 15px;
            }
        }
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
     :root {
        --content-radius: 75px;
    }
}

@media (min-width: 1400px) and (max-width: 2500px) {
     :root {
        --content-radius: 75px;
    }
}

@media (min-width: 2500px) {
    .s-home {
        svg {
            width: 100%;
            height: auto;
        }
        >.h-ape{
            margin-left: 10%;
            h2 {
                font-size: 35px;
            }
        }
    }
}