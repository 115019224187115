.hp-about {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 75px;
    padding: 2% 4% 2% 0%;
    position: relative;
    background-image: url(../../../../../../assets/images/pictures/backgrounds/purple-banana-background.png);
    text-align: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    scroll-margin-top: 15%;
    >svg {
        width: 40%;
        height: auto;
        position: absolute;
        left: -1%;
        bottom: 0;
        transform: translateX(-6.5%);
    }
    .hp-a-content {
        width: 60%;
        h1 {
            text-transform: uppercase;
            &:nth-child(4) {
                font-size: 30px;
                margin-bottom: 0;
            }
            margin-bottom: 3%;
        }
        p {
            font-family: var(--content-font);
            margin-top: 0;
            &:last-child {
                margin: 0;
            }
        }
    }
}

@media (max-width: 576px) {
    .hp-about {
        display: flex;
        justify-content: center;
        background-color: var(--dark-blue);
        border-radius: 25px;
        padding: 25px;
        position: relative;
        width: 100%;
        flex-direction: column;
        >svg {
            height: 25%;
            top: 0;
            width: auto;
            position: absolute;
            order: 2;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .hp-a-content {
            width: 100%;
            order: 1;
            h1 {
                text-transform: uppercase;
                font-size: 20px;
                &:first-child {
                    margin-top: 15%;
                    text-align: center;
                }
                &:nth-child(4) {
                    font-size: 20px;
                }
            }
            p {
                font-family: var(--content-font);
                font-size: 13px;
            }
        }
    }
}

@media (min-width: 600px) and (max-width: 1200px) {
    .hp-about {
        display: flex;
        justify-content: center;
        background-color: var(--dark-blue);
        border-radius: 25px;
        padding: 10%;
        position: relative;
        width: 100%;
        flex-direction: column;
        >svg {
            height: 30%;
            top: 0;
            width: auto;
            position: absolute;
            order: 2;
            left: 50%;
            transform: translate(-50%, -55%);
        }
        .hp-a-content {
            width: 100%;
            order: 1;
            h1 {
                text-transform: uppercase;
                font-size: 30px;
                &:first-child {
                    margin-top: 15%;
                    text-align: center;
                }
                &:nth-child(4) {
                    font-size: 25px;
                }
            }
            p {
                font-family: var(--content-font);
                font-size: 20px;
            }
        }
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .hp-about {
        .hp-a-content {
            width: 60%;
            h1 {
                text-transform: uppercase;
                font-size: 30px;
            }
            p {
                font-family: var(--content-font);
            }
        }
        >svg {
            height: 90%;
            width: auto;
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            transform: translateX(-6.5%);
        }
    }
}

@media (min-width: 1400px) and (max-width: 2500px) {
    .hp-about {
        .hp-a-content {
            width: 60%;
            h1 {
                text-transform: uppercase;
                font-size: 40px;
                &:nth-child(3) {
                    font-size: 50px;
                }
            }
            p {
                font-family: var(--content-font);
                font-size: 25px;
            }
        }
        >svg {
            width: 40%;
            height: auto;
            bottom: 0;
            left: -1%;
        }
    }
}

@media (min-width: 2500px) {
    .hp-about {
        padding: 3%;
        border-radius: 160px;
        .hp-a-content {
            width: 60%;
            h1 {
                text-transform: uppercase;
                font-size: 60px;
                &:nth-child(4) {
                    font-size: 100px;
                }
            }
            p {
                font-family: var(--content-font);
            }
        }
        >svg {
            width: 35%;
            height: auto;
            bottom: 0;
            left: -1%;
        }
    }
}