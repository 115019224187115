:root {
    --content-radius: 75px;
}

.hp-content {
    width: 100%;
    margin-top: 10%;
    padding: 0 10%;
    h1 {
        text-transform: uppercase;
    }
    >div {
        margin-bottom: 5%;
    }
}

@media (max-width: 992px) {
     :root {
        --content-radius: 25px;
    }
    .hp-content {
        margin-top: 30%;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
     :root {
        --content-radius: 75px;
    }
}

@media (min-width: 1400px) and (max-width: 2500px) {
     :root {
        --content-radius: 75px;
    }
}

@media (min-width: 2500px) {
     :root {
        --content-radius: 160px;
    }
}