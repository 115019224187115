.s-construction {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  svg {
    width: 20%;
  }
  h1 {
    font-size: 30px;
    margin: 0;
  }
}
