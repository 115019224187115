.nav-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin-top: 150px;
  width: 50%;
  padding: 0 8% 0 8%;
  overflow: hidden;
  .my-apes {
    height: 15%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    color: lightgrey;

    a {
      margin: 0;
      font-family: var(--title-font);
      color: lightgrey;
      font-weight: 900;
      letter-spacing: normal;
      > div {
        color: lightgrey;
        font-family: var(--content-font) !important;
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 800;
      }
    }
    ::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%;
      height: 1px;
      // background-color: white;
      background-color: #2c253e;
    }
    ::after {
      content: "";
      position: absolute;
      bottom: 8px;
      left: 0;
      width: 100%;
      height: 1px;
      // background-color: white;
      background-color: #2c253e;
    }
  }
  > div {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      transition: 0.5s;
      transform: scale(1.1) !important;
    }
    a,
    span {
      text-decoration: none;
      color: white;
      text-transform: uppercase;
      font-size: 22px;
      transition: 0.5s;
      font-family: var(--title-font);
      font-weight: 900;
      letter-spacing: normal;
    }
  }
  .nav-video {
    cursor: pointer;
  }
  .nav-social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    a,
    span {
      transition: 0.5s;
      cursor: pointer;
      &:last-child {
        margin-left: 20px;
      }
    }
    svg {
      width: 40px;
      height: auto;
    }
  }
  .termsMain {
    .termsLink {
      font-family: var(--title-font) !important;
      font-weight: 100 !important;
      font-size: 16px;
      text-decoration: underline;
      line-height: 1px;
    }
  }
  .nl-terms {
    font-size: 13px;
  }
  .privacyMain {
    .privacyLink {
      font-family: var(--title-font) !important;
      font-weight: 100 !important;
      font-size: 16px;
      text-decoration: underline;
      line-height: 1px;
    }
  }
  .nl-privacy {
    font-size: 13px;
  }
  > .primary-button {
    width: 110px;
    height: 30px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .nav-list {
    margin-top: 60%;
    justify-content: flex-start;
    .my-apes {
      a {
        > div {
          font-size: 13px;
          font-weight: 700;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  :root {
    --content-radius: 75px;
  }
}

@media (min-width: 1400px) and (max-width: 2500px) {
  :root {
    --content-radius: 75px;
  }
}

@media (min-width: 2500px) {
  .nav-list {
    padding: 0 10%;
    .my-apes {
      a {
        margin: 0;
        > div {
          font-size: 45px;
          font-weight: 700;
        }
      }
      ::before {
        height: 5px;
      }
      ::after {
        height: 5px;
      }
    }
    > div {
      margin-bottom: 5%;
      a,
      span {
        font-size: 50px;
      }
      &:nth-child(7) {
        margin-bottom: 10% !important;
      }
    }
    .nav-social {
      a,
      span {
        transition: 0.5s;
        cursor: pointer;
        &:last-child {
          margin-left: 20px;
        }
      }
      svg {
        width: 80px;
        height: auto;
      }
    }
    > .primary-button {
      width: 110px;
      height: 30px;
      display: flex;
      justify-content: center;
    }
    .termsMain {
      .termsLink {
        font-weight: 100 !important;
        font-size: 40px;
        text-decoration: underline;
        line-height: 1px;
      }
    }
    .nl-terms {
      font-size: 23px;
    }
  }
}
