.apeintosh-window {
    display: flex;
    position: relative;
    justify-content: center;
    height: 380px;
    img {
        width: 60%;
    }
    .apeintosh-window-bg {
        height: 100%;
    }
    .apeintosh-window-scholaroid {
        width: 35%;
        height: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .apeintosh-window-content {
        position: absolute;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        * {
            // font-family: var(--console-font);
        }
    }
}