.hp-faqs {
  text-align: center;
  h1 {
    font-size: 120px;
    margin: 0px 0px 20px 0px;
  }
}

@media (max-width: 992px) {
  .hp-faqs {
    h1 {
      font-size: 120px;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  :root {
    --content-radius: 75px;
  }
}

@media (min-width: 1400px) and (max-width: 2500px) {
  :root {
    --content-radius: 75px;
  }
}

@media (min-width: 2500px) {
  .hp-faqs {
    h1 {
      font-size: 250px;
    }
  }
}
